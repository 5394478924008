import React from 'react';
import { UserState } from './LoginState';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  rootIconButton: {
    color: '#ffffff',
    backgroundColor: '#bf2e1a',
    borderRadius: '3px',
    fontSize: '18px',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
});

export default function Logout(props) {
  const classes = useStyles();
  return( 
    <UserState.Consumer>
      { ({user, logoutHook}) => 
        <div>
          <Box p={3}>Logged in as: {user}</Box>
          <div className="login-submit-btn__div">
            <IconButton 
              disableTouchRipple
              disableRipple
              classes={{ root: classes.rootIconButton }}
              onClick={() => logoutHook()}
              className="login-submit-btn__iconbutton"
              >
              Logout
            </IconButton>
          </div>
        </div>
      }
    </UserState.Consumer>
  );
}