import React from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Error, Loading } from '../components/status/Status';
import { NetworkStatus } from '@apollo/client';
import Box from '@material-ui/core/Box';

export const withQuery = (WrappedComponent, query) => {
  return (props) => {
    const { loading, error, data, refetch, networkStatus } =
      useQuery(query, { variables: props.variables, notifyOnNetworkStatusChange: true });

    if ( networkStatus === NetworkStatus.refetch ) {
      return <div>refetching...</div>;
    }
    if (loading) {
      return <Loading />;
    } else if (error) {
      return <Error error={error} />;
    }

    return (<WrappedComponent data={data} refetch={refetch} {...props} />);
  };
}

export const withMutation = (WrappedComponent, mutation, options) => {
  return (props) => {
    const [
      callMutation,
      { error, loading, data }
    ] = useMutation( mutation, options || {} );

    if (loading) {
      return <Loading />;
    } else if (error && options?.errorPolicy !== 'all') {
      return <Error error={error} />;
    }
    const callMutationW = (opts) => {
      return callMutation(opts);
    };

    return (<WrappedComponent data={data} callMutation={callMutationW} {...props} />);
  };
}

/*
  mutations: {
    mutation_key: { mutation: m, options: o }
  }
*/

/*
export const withMutationN = (WrappedComponent, mutations) => {
  return (props) => {
    var mutstack={}
    for ( var k in mutations ) {
      const [
        callMutation,
        status
      ] = useMutation( mutations[k].mutation, mutations[k].options || {} );

      const callMutationW = (o) => {
        return callMutation(o);
      };

      mutstack[k] = {
        callMutation: callMutationW,
        status: status,
      }
    }
    return (<WrappedComponent mutations={mutstack} {...props} />);
  };
}
*/

export const withLazyQuery = (WrappedComponent, query) => {
  return (props) => {
    const [
      callQuery,
      { loading, error, data  }
     ] = useLazyQuery(query);

    if (loading) {
      console.log("lazy loading");
      return <Loading />;
    } else if (error) {
      console.log("load error", error);
      return <Error error={error} />;
    }

    return (<WrappedComponent data={data} callQuery={callQuery} {...props} />);
  };

}
