import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { productListList } from './promo_gql';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function ProductListSelect( props ) {
  return( productListList( ProductListSelectComponent, props ) );
}

function ProductListSelectComponent( props ) {
  // Translate a simple name into a product list list element that the
  // autocomplete expects to do it s work and matching against
  const getIV = () => {
    const nn = props.data.productListList.find( e => e.name === props.value );
    return nn;
  }

  const [ inputValue, setInputValue ] = useState( getIV() );

  const classes = useStyles();

  return (
    <Autocomplete
      id="product-list-select"
      style={{ width: 300 }}
      options={props.data.productListList}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <React.Fragment>
          {option.name} (last update {option.updatedAt})
        </React.Fragment>
      )}
      inputValue={ inputValue }
      onInputChange={ ( event, newval ) => setInputValue( newval ) }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a product list"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={ getIV() }
      onChange={ (event,value) => props.onChange( value?.name || "" ) }
    />
  );
}
