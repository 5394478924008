import { gql } from '@apollo/client';
import { withQuery, withMutation } from '../../hooks/withWrap';

export const PROMO_QUERY = gql`
query {
  promoList(activeOnly: false) {
    id
    promoName
    priority
    promoType
    startAt
    stopAt
    describe
    isProd
    promoProductList {
      discountPct
      productListName
    }
  }
}
`;

export function promoQuery( component, props ) {
  return withQuery( component, PROMO_QUERY )( props );
}

const PROMO_UPSERT = gql`
mutation pu( $promoIn:PromoIn!) {
  promoUpsert( promoIn:$promoIn ) {
    id
    promoName
    priority
    promoType
    startAt
    stopAt
    describe
    isProd
    promoProductList {
      discountPct
      productListName
    }
  }
}
`;

export function promoUpsert( component, props ) {
  return withMutation( component, PROMO_UPSERT, {
    update: ( cache, { data: { promoUpsert } }) => {
      const { promoList } = cache.readQuery( { 
        query: PROMO_QUERY
      });
      const newData = { promoList: [
        ...(promoList.filter( p => p.id !== promoUpsert.id )), promoUpsert
      ] };
      if ( promoUpsert === null ) { console.warn( "Null return from mutation"); return;}
      cache.writeQuery( {
        query: PROMO_QUERY,
        data: newData
      } )
    }
  } )( props );
};

const PRODUCT_LIST = gql`
query {
  productListList {
    productListId
    name
    updatedAt
  }
}
`;

export function productListList( component, props ) {
  return withQuery( component, PRODUCT_LIST )( props );
};
