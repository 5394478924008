import { gql } from '@apollo/client';

export const HELD_ORDERS = gql`
query{
    heldOrders{
      billAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      commitAt
      contact{
        billEmail
        phone
        phoneSms
        shipEmail
      }
      deliveryInstructions
      id
      items{
        catalogId
        createdAt
        description
        gtin
        qty
        salePriceStv
        sku
      }
      orderNumber
      payments{
        amountLimitStv
        amountStv
        display
        paymentType
      }
      promoCode
      shipAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      shipMethod
      totalStv
    }
  }
`;
