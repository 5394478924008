/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Loading from '../loading/Loading';
import convertISO from '../../helpers/convertISO';
import './_orders.scss';
import { HELD_ORDERS } from './heldOrders';
import { ReleaseButton, CancelButton, VoidButton } from './ActionButtons';

import SearchAppBar from './OrderControls';

// pattern from documentation; Linting rules are will be ignored

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function paymentSummary(payments) {
  return payments.reduce((acc, cv, ci) => `${acc} ${cv.paymentType} `, '');
}

function formatItem(item) {
  const salePrice = (item.salePriceStv * 0.01).toFixed(2);
  return ({ ...item, salePrice, subTotal: (salePrice * item.qty).toFixed(2) });
}

function formatData(order) {
  const {
    orderNumber,
    commitAt,
    billAddr,
    payments,
    totalStv,
    items,
  } = order;

  return {
    ...order,
    orderNo: orderNumber.toString(),
    orderDate: convertISO(commitAt),
    customerName: billAddr.attention,
    name: billAddr.attention,
    total: (totalStv / 100.0).toFixed(2),
    payments: paymentSummary(payments),
    items: items.map(formatItem),
  };
}

function address(addr) {
  if (!addr) return null;
  return (
    <>
      <TableCell>
        <p>{addr.attention}</p>
        <p>{addr.addr1}</p>
        <p>{addr.addr2}</p>
        <p>
          {addr.city}
          ,
          {' '}
          {addr.state}
        </p>
        <p>{addr.postalCode}</p>
        <p>{addr.countryCode}</p>
      </TableCell>
    </>
  );
}

function Row(props) {
  const { row, selectHook, isSelected } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Checkbox checked={isSelected} onClick={(e) => { e.preventDefault(); selectHook(row, e.target.checked); }} />
        </TableCell>
        <TableCell align="right">{row.orderNo}</TableCell>
        <TableCell align="right">{row.orderDate}</TableCell>
        <TableCell>{row.billAddr.attention}</TableCell>
        <TableCell>{row.shipAddr.attention}</TableCell>
        <TableCell>{row.contact.billEmail}</TableCell>
        <TableCell align="right">{row.total}</TableCell>
        <TableCell>{row.shipMethod}</TableCell>
        <TableCell>{row.payments}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Bill Address</TableCell>
                    <TableCell>Ship Address</TableCell>
                    <TableCell>Other Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    { address(row.billAddr) }
                    { address(row.shipAddr) }
                    <TableCell>
                      <p>
                        Phone:
                        {row.contact?.phone}
                      </p>
                      <p>
                        Ship:
                        {row.shipMethod}
                      </p>
                      <p>
                        Instructions:
                        {row.deliveryInstructions}
                      </p>
                      <p>
                        Pay:
                        {row.payments}
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>GTIN</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell>SKU</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item) => (
                    <TableRow key={item.sku}>
                      <TableCell component="th" scope="row">
                        {item.gtin}
                      </TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="right">{item.qty}</TableCell>
                      <TableCell align="right">{item.salePrice}</TableCell>
                      <TableCell align="right">{item.subTotal}</TableCell>
                      <TableCell align="right">{item.sku}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectHook: PropTypes.func.isRequired,
};

function filterRow(row, orderFilter) {
  if (orderFilter === '') return true;
  return row.orderNumber.toString().startsWith(orderFilter);
}

function formatSort(data, orderFilter) {
  return data.heldOrders.filter((r) => filterRow(r, orderFilter)).map(formatData).sort((a, b) => a.orderNo < b.orderNo);
}

const useStyles = makeStyles({
  rootButtonGroup: {
    margin: '8px',
  },
});

const OrderTable = () => {
  const classes = useStyles();
  const {
    loading: loadingOrders,
    error: errorOrders,
    data: dataOrders,
  } = useQuery(HELD_ORDERS, { fetchPolicy: 'network-only' });

  const [selection, setSelection] = useState({});
  const [confirm, setConfirm] = useState(null);
  const [noAction, setNoAction] = useState(true);
  const [orderFilter, setOrderFilter] = useState('');

  const checkAction = (sel) => {
    for (const sx in sel) {
      if (sel[sx]) return false;
    }
    return true;
  };

  const selectHook = (row, selected) => {
    // Act like a radio for now
    const s2 = {};

    // const s2 = {...selection};
    //    const [ prior, arowx ] = (s2[row.id] || [] );
    const [prior] = (s2[row.id] || []);

    if (prior) {
      s2[row.id] = [!prior, row];
    } else {
      s2[row.id] = [selected, row];
    }
    setSelection(s2);
    setNoAction(checkAction(s2));
  };
  const isSelected = ({ id }) => (selection[id] || [])[0] === true;

  const confirmHandler = (mutator, forWhat) => {
    setConfirm({ mutator, forWhat });
  };

  const doConfirm = () => {
    const vars = { variables: { checkoutId: Object.keys(selection)[0] } };

    confirm.mutator(vars);
    setConfirm(null);
    setSelection({});
  };
  const dontConfirm = () => {
    setConfirm(null);
  };

  const clearSelection = () => {
    setSelection({});
    setConfirm(null);
    setNoAction(true);
  };

  if (loadingOrders) return <Loading />;

  if (errorOrders) return <Redirect to="/error" />;

  return (
    <>
      <SearchAppBar searchChange={setOrderFilter}>
        <ReleaseButton forWhat={confirm?.forWhat} disabled={noAction} confirm={confirmHandler} />
        <CancelButton forWhat={confirm?.forWhat} disabled={noAction} confirm={confirmHandler} />
        <VoidButton forWhat={confirm?.forWhat} disabled={noAction} confirm={confirmHandler} />
        <ButtonGroup className={classes.rootButtonGroup}>
          <Button variant="contained" color="secondary" disabled={noAction} onClick={clearSelection}>Clear Selection</Button>
          <Button variant="contained" disabled={confirm === null} onClick={doConfirm}>Confirm</Button>
          <Button variant="contained" color="secondary" disabled={confirm === null} onClick={dontConfirm}>Cancel</Button>
        </ButtonGroup>
      </SearchAppBar>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Order#</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Bill Name</TableCell>
              <TableCell>Ship Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell>Ship By</TableCell>
              <TableCell>Payment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { formatSort(dataOrders, orderFilter).map((row) => (
              <Row
                key={row.id}
                row={row}
                selectHook={selectHook}
                isSelected={isSelected(row)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

export default OrderTable;
