import React from 'react';
import './_error.scss';

const Error = () => (
  <div className="error-container__div">
    <main className="error-content__main">
      <div className="error-content__div">
        <p>Something went horribly wrong</p>
      </div>
    </main>
  </div>
);

export default Error;
