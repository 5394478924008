import React, {useState} from 'react';

import { useMutation } from '@apollo/client';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';

import { listBlacklist,
         ADD,
         REM,
         cacheUpdate } from './blacklist_gql';
import { Error, Loading } from '../status/Status';

function row( item, remHook ) {
  return(
    <TableRow key={item.gtin}>
      <TableCell><Button onClick={() => remHook( item.gtin )} >Remove</Button></TableCell>
      <TableCell>{item.gtin}</TableCell>
      <TableCell>{item.title}</TableCell>
      <TableCell>{item.inserted_at}</TableCell>
    </TableRow>
  );
}

function NewRow() {
  const [ gtin, setGtin ] = useState("");
  const [ callMutation, { error, loading, data } ] =
        useMutation( ADD, { update: cacheUpdate } );

  const addHook = () => {
    callMutation( { variables: { gtin: gtin } } )
      .then( () => setGtin("") )
      .catch( () => true );
  }
  const showStatus = () => {
    if (loading) {
      return <Loading />;

    } else if ( error ) {
      return <Error error={error}/>;
    }
  }

  return(
    <TableRow key="add">
      <TableCell><Button onClick={() => addHook()} >Add</Button></TableCell>
      <TableCell>
        <TextField
          id="standard-basic"
          label="GTIN"
          value={gtin}
          onChange={ e => setGtin(e.target.value) }
        />
      </TableCell>
      <TableCell>{ showStatus() }</TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}

function body( list, remHook ) {
  return(
    <TableBody>
      <NewRow/>
      { list.map( e => row( e, remHook ) ) }
    </TableBody>
  );
}

function BlackTable( { data: { list } } ) {
  const [ remMutation, { error, loading, data } ] =
        useMutation( REM, { update: cacheUpdate } );

  const remHook = ( gtin ) => {
    remMutation( { variables: { gtin: gtin } } )
      .catch( () => true );
  };

  const showStatus = () => {
    if (loading) {
      return <Loading />;

    } else if ( error ) {
      return <Error error={error}/>;
    }
  }

  return(
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>GTIN</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Added</TableCell>
            </TableRow>
          </TableHead>
          { body( list, remHook )}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default function Blacklist( props ) {
  return( listBlacklist( BlackTable, props ) );
}
