import React from 'react';

import Button from '@material-ui/core/Button';

import { withMutation } from '../../hooks/withWrap';
import { RELEASE_ORDER, CANCEL_ORDER, VOID_ORDER } from './order_gql';

function ReleaseButtonDisplay( { callMutation, disabled, confirm, forWhat } ) {
  const buttonHandler = () => { confirm( callMutation, "release" ) };
  return(
    <Button size="small" variant="contained" color={isForWhat(forWhat,"release")} disabled={disabled} onClick={buttonHandler}>Release Orders</Button>
  );
}

export function ReleaseButton( props ) {
  return withMutation( ReleaseButtonDisplay, RELEASE_ORDER )( props );
}

export function CancelButton( props ) {
  return withMutation( CancelButtonDisplay, CANCEL_ORDER )( props );
};

export function CancelButtonDisplay( { callMutation, disabled, confirm, forWhat }) {
  const buttonHandler = () => { confirm( callMutation, "cancel" ) };
  return(
    <Button size="small" variant="contained" color={isForWhat(forWhat,"cancel")} disabled={disabled} onClick={buttonHandler}>Cancel Orders</Button>
  );
}

export function VoidButtonDisplay( { callMutation, disabled, confirm, forWhat }) {
  const buttonHandler = () => { confirm( callMutation, "void" ) };
  return(
    <Button size="small" variant="contained" color={isForWhat(forWhat,"void")} disabled={disabled} onClick={buttonHandler}>Void Orders</Button>
  );
}

export function VoidButton( props ) {
  return withMutation( VoidButtonDisplay, VOID_ORDER )( props );
};

function isForWhat( forWhat, label ) {
  return forWhat === label ? "default" : "primary";
}
