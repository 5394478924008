import { gql } from '@apollo/client';
import { withQuery, withMutation } from '../../hooks/withWrap';

const LIST = gql`
query {
  list: ingramBlockedList {
    gtin
    title
    inserted_at
  }
}
`;

export const ADD = gql`
mutation eanAdd( $gtin:String! ) {
   add: ingramBlockedAdd( gtin: $gtin ) {
gtin
title
inserted_at
}
}
`;

export const REM = gql`
mutation eanRem( $gtin:String! ) {
   rem: ingramBlockedRem( gtin: $gtin ) {
gtin
}
}
`;

export function listBlacklist( component, props ) {
  return withQuery( component, LIST )( props );
}

export function cacheUpdate( cache, { data } ) {
  console.log( "Data is", data );

  const change = data.add || data.rem;

  const { list } = cache.readQuery( {
    query: LIST
  });

  const trimmed = list.filter( p => p.gtin !== change.gtin );
  var newData;

  if ( data.add ) {
    newData = { list: [ change, ...trimmed ] };
  } else {
    newData = { list: trimmed };
  }

  cache.writeQuery( {
    query: LIST,
    data: newData
  });
}

export function addBlacklist( component, props ) {
  return withMutation( component, ADD, {
    update: cacheUpdate
  })( props );
}

export function remBlacklist( component, props ) {
  return withMutation( component, REM, {
    update: cacheUpdate
  })( props );
}
