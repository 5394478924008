import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Redirect } from 'react-router-dom';

export const Error = ({error}) => {
  return( <Box component="span" m={1}>
    { error.message }
  </Box>);
};

const useStyles = makeStyles({
  rootProgress: {
    color: '#bf2e1a',
    width: '80px !important',
    height: '80px !important'
  },
  loading: {
    padding: '5px',
    margin: '50px auto',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
});

export const Loading = () => {
  const classes = useStyles();

  const [timeoutState, setTime] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(true);
    }, 60000);
    return () => clearTimeout(timer);
  });

  return (
    <div classes={{ root: classes.loading }}>
      {
        timeoutState && <Redirect to={{ pathname: '/error' }} />
      }
      <CircularProgress classes={{ root: classes.rootProgress }} />
    </div>
  );
};
