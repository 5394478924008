const convertISO = (dateStr) => {
  const formatDate = new Date(dateStr);
//  const formatYear = formatDate.getFullYear();
  const formatMonth = formatDate.getMonth() + 1;
  const formatDay = formatDate.getDate();
//   const formatTime = formatDate.getTime();
  const formatHours = formatDate.getHours();
  const formatMinutes = formatDate.getMinutes();

  return `${formatMonth}-${formatDay} ${formatHours % 12}:${formatMinutes < 10 ? `0${formatMinutes}` : formatMinutes}${formatHours > 11 ? 'PM' : 'AM'}`;
};

export default convertISO;
