import React, { useState } from 'react';
import Login from './login/Login';
import { LOGOUT, IS_LOGGED_IN } from './login_gql';
import { withQuery, withMutation } from '../hooks/withWrap';


export const UserState = React.createContext(false);

const Logout = ( { callMutation, setLogin, loginState, refetch, children }) => {
  const logout = () => { 
    callMutation().then( () => refetch() );
  };

  if ( loginState ) {
    return(
      <UserState.Provider value={ {logoutHook: logout, user: loginState} } >
        { children }
      </UserState.Provider>
    );
  }
  // else
  return( <Login setLoginValid={setLogin} />);
  
};

const LoginState = ( { data, refetch, children } ) => {
  const [loginState, setLogin] = useState(data.isLoggedIn);

  return(
    withMutation( Logout, LOGOUT )({loginState, setLogin, refetch, children})
  );
};

export default withQuery(LoginState, IS_LOGGED_IN);