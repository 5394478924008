import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, HttpLink, InMemoryCache, concat, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Routes from '../src/components/routes/Routes';
import './_app.scss';

import LoginState from './components/LoginState';


const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  // credentials: 'same-origin',
  credentials: 'include'
 });

const errhandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      {
        return console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
      });
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(errhandler, httpLink),
});

const App = () => (
  <ApolloProvider client={client}>
    <div className="app-container__div">
      <LoginState>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </LoginState>
    </div>
  </ApolloProvider>
);

export default App;
