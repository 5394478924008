import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CUSTOMER_LOOKUP, CUSTOMER_UPDATE } from './customerLookup';
import { withMutation, withQuery } from '../../hooks/withWrap';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './_customerInfo.scss';

const useStyles = makeStyles({
  rootTextField: {
    width: '225px',
    fontSize: '15px',
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
  },
  rootButton: {
    color: '#ffffff',
    backgroundColor: ' #af272f',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
});

const useControl = ( key, onChange, init ) => {
  const [ checked, setChecked ] = React.useState( init == null ? '' : init );
  const handleValue = (value) => {
    setChecked(value);
    var a={};
    a[key] = value;
    onChange( a );
  };
  const handleEventValue = (event) => handleValue(event.target.value);
  const handleEventChecked = (event) => handleValue(event.target.checked);

  return { state: checked, update: handleValue, eventValue: handleEventValue, eventChecked: handleEventChecked };
}

const CustomerCanPoRadio = ( { canPo, onChange }) => {
  const { state, eventChecked } = useControl( "canPo", onChange, canPo || false );
  
  return (
      <FormGroup row>
        <FormControlLabel
        control={<Checkbox checked={state} name="canPo" onChange={eventChecked } />}
        label="Can Po?"
        />
      </FormGroup>
  );
}

const CustomerBasilAccount = ( { basilAccountId, onChange }) => {
  const { state, eventValue } = useControl( "basilAccountId", onChange, basilAccountId);
  return(
    <TextField id="standard-basic" label="Basil Account" value={state} onChange={ eventValue }/>
  );
};
const CustomerEmail = ( { email, onChange }) => {
  const { state, eventValue } = useControl( "email", onChange, email);
  return(
    <TextField id="standard-basic" label="Email" value={state} onChange={ eventValue }/>
  );
};

const CustomerPhone = ( { phoneE164, onChange }) => {
  const { state, eventValue } = useControl( "phoneE164", onChange, phoneE164);
  return(
    <TextField id="standard-basic" label="Phone" value={state} onChange={ eventValue }/>
  )
};

const CustomerChanges = ( props ) => {
  return withMutation( CustomerChangesDisplay, CUSTOMER_UPDATE, { errorPolicy: 'all'} )( props );
}

const ShowChanges = ( { changes } ) => {
  return(
    <div>Pending Changes:
      <ul>
        { Object.keys( changes ).map( (k,i) => <li key={i}>{k}: { `${ changes[k]}` }</li>)}
      </ul>
    </div>
  );
}
const CustomerChangesDisplay = ( { changes, callMutation, reset, reportError, data }) => {
  const classes = useStyles();
  const handleClick = () => {
    callMutation( { variables: { changes }})
    .then( ( { data, errors } ) => {
      if ( errors ) { reportError( errors ); } else { reset(); }
     });
  };
  if ( Object.keys( changes ).length === 0 ) {
    return(null);
  }
  return(
    <div>
      <ShowChanges changes={changes} />
      <Button
      type="button"
      classes={{ root: classes.rootButton }}
      onClick={handleClick}
        >
        Submit
      </Button>
    </div>
  );
};

const CustomerData = ( { email, ...props } ) => {
  if ( email ) {
    return withQuery(CustomerDataDisplay, CUSTOMER_LOOKUP)({ variables: { emailAddress: email } });
  }
  return <div>waiting</div>;
};

const FieldError = ( { field, errorMap }) => {
  if ( errorMap[field] ) {
    return(<p style={{color: 'red'}}>{errorMap[field]}</p>);
  }
  return null;
}

const CustomerDataDisplay = ( { data: dataLookup, refetch })  => {
  const classes = useStyles();
  const [ changes, setChanges ] = React.useState( {} );
  const [ version, setVersion ] = React.useState( 1 );
  const [ errors, setErrors ] = React.useState( { general: [], fields: 0 } );

  const customer = dataLookup?.customerLookup?.customer;

  const handleChange = (change) => {
    setChanges( { ...changes, ...change, customerId: customer.customerId } );
  };

  const reset = () => {
    setVersion(version+1);
    setChanges({});
    setErrors({ general: [], fields: 0 });
    refetch();
  };

  const reportError = (errorList) => {
    const newError={ fields: 0, general: [] }
    for( let { field, message, path } of errorList ) {
      if ( path && path[0] === "customerChanges" ) {
        newError[field]=message;
        newError.fields++;
      } else {
        newError.general = newError.general.concat( message );
      }
     };
    setErrors(newError);
  };

  if ( !customer ) {
    return(
      <div className="customerinfo-details__div customerinfo-details-noresult__div">
      <p className="customerinfo-details__p">
        The customer does not exist.
      </p>
    </div>
     )
  }
  return(
    <div key={version}>
      <div className="customerinfo-border__div" />
      <section className="customerinfo-info__section">
        <div className="customerinfo-searchterm__div">
          <p className="customerinfo-searchterm__p">
            <span className="customerinfo-searchterm-header__span">
              Search results for:&nbsp;
            </span>
            {dataLookup?.customerLookup?.emailAddressSearch}
          </p>
        </div>
        { errors.general.length > 0 && ( <div><h2>General Errors:</h2><ul>{errors.general.map( (m,i) => <li key={i}>{m}</li> )}</ul></div> ) }
        { errors.fields > 0 && <p>See field errors below:</p>}
        <CustomerChanges changes={changes} reset={reset} reportError={reportError}/>
        <Button
          type="button"
          classes={{ root: classes.rootButton }}
          onClick={reset}
        >
          Reset
        </Button>

        <div className="customerinfo-details__div">
          <p className="customerinfo-details__p">
            <span className="customerinfo-details-header__span">Customer ID:&nbsp;</span>
            { customer.customerId || 'N/A' }
          </p>

          <p className="customerinfo-details__p">
            <span className="customerinfo-details-header__span">First Name:&nbsp;</span>
            { customer.firstName || 'N/A' }
          </p>

          <p className="customerinfo-details__p">
            <span className="customerinfo-details-header__span">Last Name:&nbsp;</span>
            { customer.lastName || 'N/A' }
          </p>

          <div className="customerinfo-details__p">
            <CustomerEmail email={customer.email} onChange={handleChange} />
            <FieldError field="email" errorMap={errors} />
          </div>

          <div className="customerinfo-details__p">
            <CustomerPhone phoneE164={customer.phoneE164} onChange={handleChange} />
            <FieldError field="phoneE164" errorMap={errors} />
          </div>

          <div className="customerinfo-details__p">
            <CustomerCanPoRadio canPo={customer.canPo} onChange={handleChange} />
            <FieldError field="canPo" errorMap={errors} />
          </div>

          <div className="customerinfo-details__p">
            <CustomerBasilAccount basilAccountId={customer.basilAccountId} onChange={handleChange} />
            <FieldError field="basilAccountId" errorMap={errors} />
          </div>
          
        </div>
      </section>
    </div>);
};

const CustomerInfo = () => {
  const classes = useStyles();

  const [lookupState, setLookup] = useState('');
  const [ email, setEmail ] = useState(false);

  const handleLookup = (e) => {
    e.preventDefault();
    setEmail(lookupState);
  };

  return (
    <div className="customerinfo-container__div">
      <main className="customerinfo-content__main">
        <div className="customerinfo-content__div">
          <h1 className="customerinfo-header__h1">Customer Lookup</h1>
          <section className="customerinfo-form__section">
            <div className="customerinfo-form__div">
              <form onSubmit={handleLookup} className="customerinfo-form__form">
                <div className="customerinfo-input__textfield">
                  <TextField
                    id="lookup"
                    onChange={(e) => setLookup(e.target.value)}
                    label="Customer Email Address"
                    type="email"
                    value={lookupState}
                    variant="outlined"
                    classes={{ root: classes.rootTextField }}
                  />
                </div>
                <div className="customerinfo-search__button">
                  <Button
                    type="submit"
                    classes={{ root: classes.rootButton }}
                  >
                    Search
                  </Button>
                </div>
              </form>
            </div>
          </section>
          <CustomerData email={email} />
        </div>
      </main>
    </div>
  );
};

export default CustomerInfo;
