import React from 'react';
import './_pageNotFound.scss';

const PageNotFound = () => (
  <div className="pagenotfound-container__div">
    <main className="pagenotfound-content__main">
      <div className="pagenotfound-content__div">
        <p>What are you doing here on this page?</p>
      </div>
    </main>
  </div>
);

export default PageNotFound;
