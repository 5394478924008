import { gql } from '@apollo/client';

export const CUSTOMER_LOOKUP = gql`
  query($emailAddress: String!){
    customerLookup(emailAddress: $emailAddress){
      customer{
        customerId
        email
        firstName
        lastName
        phoneE164
        verified
        canPo
        basilAccountId
      }
      emailAddressSearch
    }
  }
`;

export const CUSTOMER_UPDATE = gql`
  mutation($changes: customerIn!) {
    customerChanges( changes: $changes) {
      customer {
        customerId
        email
        firstName
        lastName
        phoneE164
        verified
        canPo
        basilAccountId
      }
    }
  }
`;
