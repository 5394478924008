import React from 'react';
import {
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PageNotFound from '../pageNotFound/PageNotFound';
import Error from '../error/Error';
import Home from '../home/Home';
import './_routes.scss';

const theme = createMuiTheme({
/*  palette: {
    primary: {
      main: '#bf2e1a',
      light: '#d4301a',
      dark: '#780f00',
    },
  }, */
  typography: {
    fontFamily: [
      '"Trade Gothic Lt Std"',
      '"Proxima Nova, sans-serif"',
      'Arial',
      'Helvetica',
    ].join(','),
    fontSize: 15,
  },
});

const Routes = () => (
  <ThemeProvider theme={theme}>
    <Switch>
      {/* Main Nav Routes */}
      <Route exact path="/" component={Home} />
      <Route path="/page-not-found" component={PageNotFound} />
      <Route path="/error" component={Error} />
      <Redirect to="/page-not-found" />
    </Switch>
  </ThemeProvider>
);

export default Routes;
