import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';

import { promoQuery, promoUpsert } from './promo_gql';
import PromoControl from './PromoControl';
import PromoEdit from './Edit';
import omitDeep from '../../helpers/omitDeep';

function row( promoItem, editItem ) {
  return(
    <TableRow key={promoItem.id}>
      <TableCell><Button onClick={() => editItem( promoItem )}>Edit</Button></TableCell>
      <TableCell>{promoItem.promoName}</TableCell>
      <TableCell>{promoItem.promoType}</TableCell>
      <TableCell>{promoItem.startAt}</TableCell>
      <TableCell>{promoItem.stopAt}</TableCell>
      <TableCell>{promoItem.promoProductList.discountPct}</TableCell>
      <TableCell>{promoItem.isProd ? "Y" : "N"}</TableCell>
    </TableRow>
  )
}
function body( promoList, editItem ) {
  return(
    <TableBody>
      { promoList.map( r => row(r, editItem ) )}
    </TableBody>
  );
}

function PromoTable( { editItem, data: { promoList } } ) {
  return(
    <React.Fragment>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>Stop</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Prod</TableCell>
            </TableRow>
          </TableHead>
          { body( promoList, editItem )}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function PromoDisplay( props ) {
  return( promoQuery( PromoTable, props) );
}

export function PromosControl( { callMutation, data, ...props } ) {
  const [ create, showCreate ] = useState( false );
  const [ edit, setEdit ] = useState(null);
  const [ submission, registerForSubmit ] = useState( null);

  const fixSubmit = ( sub ) => {
    return omitDeep( { ...sub, promoType: "PRODUCT_LIST" }, "__typename" );
  };

  const handleSubmit = () => {
    if ( submission ) {
      callMutation( { variables: { promoIn: fixSubmit(submission) } });
      showCreate(false);
      setEdit(null);
    }
  }

  return(
    <React.Fragment>
      <PromoControl>
        <Button variant="contained" onClick={() => showCreate(true)}>Create</Button>
        <Button variant="contained" disabled={!(create || edit )} onClick={handleSubmit}>Submit</Button>
        <Button variant="contained" color="secondary" onClick={() => { showCreate(false); setEdit(null)} }>Cancel</Button>
      </PromoControl>
      { 
        (create || edit ) ? <PromoEdit edit={edit} registerForSubmit={registerForSubmit}/> : <PromoDisplay editItem={setEdit}/>
      }
    </React.Fragment>
  );
}

export default function Promos( props ) {
  return( promoUpsert( PromosControl, props ));
}
