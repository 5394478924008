import { gql } from '@apollo/client';

export const RELEASE_ORDER = gql`
  mutation orderRelease($checkoutId: ID!){
    releaseOrder(checkoutId: $checkoutId){
      success
      id
    }
  }
`;
export const CANCEL_ORDER = gql`
  mutation orderCancel( $checkoutId: ID! ) {
    cancelOrder( checkoutId: $checkoutId ) {
      success
      id
    }
  }
`;

export const VOID_ORDER = gql`
  mutation orderVoid( $checkoutId: ID! ) {
    voidOrder( checkoutId: $checkoutId ) {
      success
      id
    }
  }
`;
