import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './_loading.scss';

const useStyles = makeStyles({
  rootProgress: {
    color: '#bf2e1a',
  },
});

const Loading = () => {
  const classes = useStyles();

  const [timeoutState, setTime] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(true);
    }, 60000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="loading-container__div">
      {
        timeoutState && <Redirect to={{ pathname: '/error' }} />
      }
      <CircularProgress className="loading-material__circularprogress" classes={{ root: classes.rootProgress }} />
    </div>
  );
};

export default Loading;
