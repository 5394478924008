import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
// import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Loading from '../loading/Loading';
import strandLogo from './strand-logo-exact.png';
import './_login.scss';

const useStyles = makeStyles({
  rootAppBar: {
    justifyContent: 'center',
  },
  appbarColorPrimary: {
    //backgroundColor: '#fbf5df',
    //backgroundColor: '#bf2e1a',
  },
  rootTextField: {
    width: '100%',
    maxWidth: '500px',
    margin: '10px 0',
  },
  rootIconButton: {
    color: '#ffffff',
    backgroundColor: '#bf2e1a',
    borderRadius: '3px',
    fontSize: '18px',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
});

const LOGIN = gql`
  mutation userLogin($password: String, $username: String){
      login(password: $password, username: $username)
    }
`;

const Login = ( { setLoginValid }) => {
  const classes = useStyles();
  const [loginState, setLogin] = useState({
    userName: '',
    password: '',
  });

  const [
    login,
    {
      error: errorLogin,
      loading: loadingLogin
    },
  ] = useMutation(LOGIN);

  if (loadingLogin) {
    return (
      <>
        <AppBar
          position="fixed"
          className="login-appbar__appbar"
          classes={{ colorPrimary: classes.appbarColorPrimary, root: classes.rootAppBar }}
        >
          <div className="login-appbar-center__div">
            <div className="login-logo__div">
              <img className="login-logo__img" src={strandLogo} alt="Strand Book Store" />
            </div>
          </div>
        </AppBar>
        <Toolbar />
        <div className="login-container__div">
          <Loading />
        </div>
      </>
    );
  }

//   if (errorLogin) return <Redirect to="/error" />;

  //if (dataLogin && dataLogin.login) { setLoginValid(true) };

  const handleLogin = (field) => (
    (e) => setLogin({ ...loginState, [field]: e.target.value })
  );

  const handleSubmit = () => {
    login({ variables: { username: loginState.userName, password: loginState.password } })
      .then((d) => { 
        const userName=loginState.userName;
        setLogin({ userName: '', password: '', });
        if ( d?.data?.login ) { setLoginValid(userName); }
      }
      )
      .catch((err) => console.error(err));
  };

  return (
    <>
      <AppBar
        position="fixed"
        className="login-appbar__appbar"
        classes={{ colorPrimary: classes.appbarColorPrimary, root: classes.rootAppBar }}
      >
        <div className="login-appbar-center__div">
          <div className="login-logo__div">
            <img className="login-logo__img" src={strandLogo} alt="Strand Book Store" />
          </div>
        </div>
      </AppBar>
      <Toolbar />
      <div className="login-container__div">
        <main className="login-content__main">
          <div className="login-content__div">
            <div className="login-form__div">
              {
                errorLogin
                  && errorLogin.graphQLErrors
                  && errorLogin.graphQLErrors.map((err, i) => {
                    const {
                      message,
                    } = err;

                    return (
                      <div className="login-error__div" key={`${i + 400}error`}>
                        <ErrorIcon />
                        <p className="login-error-msg__p">{`${message}. Username and/or password is incorrect. Please try again.`}</p>
                      </div>
                    );
                  })

              }
              <form onSubmit={handleSubmit} className="login-form__form">
                <TextField
                  id="username"
                  label="Username"
                  value={loginState.userName}
                  onChange={handleLogin('userName')}
                  variant="outlined"
                  classes={{ root: classes.rootTextField }}
                  required
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                />
                <TextField
                  id="password"
                  label="Password"
                  value={loginState.password}
                  onChange={handleLogin('password')}
                  variant="outlined"
                  classes={{ root: classes.rootTextField }}
                  required
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  type="password"
                />
                <div className="login-submit-btn__div">
                  <IconButton disableTouchRipple disableRipple classes={{ root: classes.rootIconButton }} type="submit" className="login-submit-btn__iconbutton">
                    Login
                  </IconButton>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Login;
