import { gql } from '@apollo/client';

export const IS_LOGGED_IN = gql`
  query{
    isLoggedIn
  }
`;

export const LOGOUT = gql`
  mutation { logout }
`;

