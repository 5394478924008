import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/styles';

import ProductList from './ProductList';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function DynamicList( { list, onChange } ) {
  const handleChange = ( index, value ) => {
    return onChange( value, index )
  }

  return(
    <React.Fragment>
      {
        [ ...list, ""].map( (val, index) => {
          return <ProductList
                   key={index}
                   label="Basil List"
                   value={val}
                   onChange={ (newval) => handleChange(index,newval) }
                 />;
        }
        )
      }

    </React.Fragment>
  );
}

function defaultPromo() {
  const d1 = new Date().toISOString().split("T")[0];

  return {
    isProd: false,
    promoProductList: {
      productListName: [],
      discountPct: 20
    },
    startAt: d1 + " 09:00",
    stopAt: d1 + " 17:00"
  };
}
export default function PromoEdit( { edit, registerForSubmit } ) {
  const classes = useStyles();
  const [ form, updateForm ] = useState( edit || defaultPromo() );
  const [ errs, updateErr ] = useState({});

  useEffect( () => registerForSubmit( form ));

  const handleChange = (event) => {
    var val = event.target.value;
    const name = event.target.name;

    switch(name) {
      case "startAt":
      case "stopAt":
        if ( val.match(/^202\d-[01]\d-[0123]\d [012]\d:[0-5]\d$/)) {
          updateErr( delete {...errs}[name])
        } else {
          const e2={...errs};
          e2[name] = "date/24htime: yyyy-mm-dd hh:mm";
          updateErr( e2 );
        }
      break;
    case "isProd":
      val = event.target.checked;
      break;
      default:
    }

    const form2 = {...form};
    form2[name] = val;
    updateForm(form2);
  }

  const updateDiscount = (event) => {
    const val = event.target.value;

    if ( val.match(/^[0-9]?[0-9]?$/)) {
      updateErr( delete {...errs}.discountPct )
    } else {
      updateErr( {...errs, discountPct: "Up to 2 digits"} )
    }

    updateForm( {...form, promoProductList: { ...form.promoProductList, discountPct: parseInt(val) }})
  }

  const handleListChange = (value,pos) => {
    const n0 = form.promoProductList.productListName || [];
    const n1 = [...n0];
    n1[pos] = value;
    const n2 = n1.filter( n2 => n2 !== "");
    updateForm( {...form, promoProductList: { ...form.promoProductList, productListName: [...n2] } } )
  }


  return(
  <form className={classes.root} noValidate autoComplete="off">
    <TextField
      id="standard-basic"
      label="URL Fragment"
      name="promoName"
      value={form.promoName}
      onChange={handleChange}/>
    <TextField
      id="standard-basic"
      label="Cart text"
      name="describe"
      value={form.describe}
      onChange={handleChange}/>
    <TextField
      error={!!errs.startAt}
      helperText={errs.startAt}
      id="standard-basic"
      label="Start At"
      name="startAt"
      value={form.startAt}
      onChange={handleChange}/>
    <TextField
      error={!!errs.stopAt}
      helperText={errs.stopAt}
      id="standard-basic"
      label="Stop At"
      name="stopAt"
      value={form.stopAt}
      onChange={handleChange}/>
    <TextField
      error={!!errs.discountPct}
      helperText={errs.discountPct}
      id="standard-basic"
      label="Discount %" name="discountPct"
      value={form.promoProductList?.discountPct}
      onChange={updateDiscount}/>
    <FormGroup row>
      <FormControlLabel
        control={<Checkbox checked={form.isProd} onChange={handleChange} name="isProd" />}
        label="Is Prod"
      />
    </FormGroup>
    <Box>
      <DynamicList list={form.promoProductList.productListName} onChange={handleListChange}/>
    </Box>
  </form>
  );
}
