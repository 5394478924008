import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  rootToolbar: {
    overflowX: 'scroll',
  },
  searchRoundedIcon: {
    color: '#ffffff',
  },
  closeIcon: {
    color: '#ffffff',
  },
}));

export default function SearchAppBar({ children, searchChange }) {
  const classes = useStyles();
  const match425 = useMediaQuery('(min-width: 425px)');

  const {
    compSwitchState: searchState,
    handleCompOpen: handleSearchOpen,
    handleCompClose: handleSearchClose,
  } = useOpenCloseComponent();

  return (
    <>
      <AppBar position="sticky">
        {
          searchState
            ? (
              <Toolbar>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchRoundedIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => searchChange(e.target.value)}
                  />
                </div>
                <IconButton onClick={handleSearchClose} arial-label="close">
                  <CloseRoundedIcon className={classes.closeIcon} />
                </IconButton>
              </Toolbar>
            )
            : (
              <Toolbar className={classes.rootToolbar}>
                {children}
                {
                  match425
                    ? (
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchRoundedIcon />
                        </div>
                        <InputBase
                          placeholder="Search…"
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          inputProps={{ 'aria-label': 'search' }}
                          onChange={(e) => searchChange(e.target.value)}
                        />
                      </div>
                    )
                    : (
                      <IconButton onClick={handleSearchOpen} arial-label="search">
                        <SearchRoundedIcon className={classes.searchRoundedIcon} />
                      </IconButton>
                    )
                }
              </Toolbar>
            )
        }
      </AppBar>
      <Toolbar />
    </>
  );
}

SearchAppBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  searchChange: PropTypes.func.isRequired,
};
